import React from 'react'
import Layout from '../../components/layout'
import { MatchingCreator } from '../../components/ChallengeCreators'

const MatchingCreatorPage = ({ learningGoalId }) => (
  <Layout>
    <MatchingCreator learningGoalId={learningGoalId} />
  </Layout>
)

export default MatchingCreatorPage
